<template>
    <div class="amher">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first" label="运营信息发布">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-select v-model="campus_id" placeholder="校区" clearable @change="initData"
                                    size="mini">
                                    <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <el-input placeholder="请输入标题名称" v-model="search_inp" clearable @change="initData"
                                    size="mini">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="handle_add">添加</el-button>
                                <el-button class="delBtn" @click="handle_del_mult">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                @selection-change="SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID" min-width="100">
                                </el-table-column>
                                <el-table-column prop="title" label="标题">
                                </el-table-column>
                                <el-table-column prop="campus_uid" label="校区">
                                </el-table-column>
                                <el-table-column prop="class_uid" label="班级">
                                </el-table-column>
                                <el-table-column prop="creat_uid" label="发布人">
                                </el-table-column>
                                <el-table-column prop="parent_uid" label="家长">
                                </el-table-column>
                                <el-table-column prop="send_time" label="发送时间" min-width="100">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                        <span @click="handle_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="count" :initData="initData" @changePage="changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane name="second" label="老师信息发布">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-select v-model="campus_id" placeholder="校区" clearable @change="initData"
                                    size="mini">
                                    <el-option v-for="item in campus_arr" :key="item.uid" :label="item.name"
                                        :value="item.uid">
                                    </el-option>
                                </el-select>
                                <el-input placeholder="请输入标题名称" v-model="search_inp" clearable @change="initData"
                                    size="mini">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="handle_add">添加</el-button>
                                <el-button class="delBtn" @click="handle_del_mult">删除</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                                @selection-change="SelectionChange"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column type="selection" width="55">
                                </el-table-column>
                                <el-table-column prop="uid" label="ID" min-width="100">
                                </el-table-column>
                                <el-table-column prop="title" label="标题">
                                </el-table-column>
                                <el-table-column prop="campus_uid" label="校区">
                                </el-table-column>
                                <el-table-column prop="class_uid" label="班级">
                                </el-table-column>
                                <el-table-column prop="creat_uid" label="发布人">
                                </el-table-column>
                                <el-table-column prop="parent_uid" label="家长">
                                </el-table-column>
                                <el-table-column prop="send_time" label="发送时间" min-width="100">
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="handle_Edit(scope.row.uid)">编辑</span>
                                        <span @click="handle_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="count" :initData="initData" @changePage="changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- 弹框部分 -->
        <!-- 添加编辑弹框*****************************************************************-->
        <el-dialog :visible.sync="add_edit_dialog" width="828px" :before-close="handleClose" @close="handle_reset">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">{{title_name}}</div>
            </div>
            <!-- 表单验证内容 -->
            <el-form label-width="80px">
                <el-form-item label="标题">
                    <el-input v-model="title" placeholder="请输入标题" clearable size="mini"></el-input>
                </el-form-item>
                <el-form-item label="校区">
                    <el-select v-model="school_name" placeholder="请选择校区" clearable multiple @change="Change_campus"
                        ref="sel" size="mini">
                        <el-option v-for="item in school" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="班级">
                    <el-select v-model="class_name" placeholder="请选择班级" clearable multiple @change="Change_class"
                        ref="sel_class" size="mini">
                        <el-option v-for="item in class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="家长">
                    <el-select v-model="parent_id" placeholder="请选择家长" clearable multiple ref="sel_pa" size="mini"
                        @change="handle_sel">
                        <el-option v-for="item in parent_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发布人">
                    <el-select v-model="publisher_id" placeholder="请选择发布人" clearable size="mini">
                        <el-option v-for="item in publisher" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发布内容">
                    <el-input type="textarea" v-model="push_content" placeholder="请输入发布内容" clearable
                        style="width:472px">
                    </el-input>
                </el-form-item>
                <el-form-item label="照片/视频" class="img_load">
                    <div style="display:flex">
                        <el-input style="width:288px;height:30px" placeholder="请上传图片/视频" disabled>
                        </el-input>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/msg_up_img"
                            :headers=myHeaders accept=".png,.gif,.jpg,.jpeg" name="image"
                            :on-success="handleSuccess_img" :show-file-list="false" :before-upload="handele_header">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传照片
                            </el-button>
                        </el-upload>
                        <el-upload class="upload-demo" action="https://education.ztxinde.com/msg_up_vid"
                            :headers=myHeaders name="image" :on-success="handleSuccess_video" :show-file-list="false"
                            accept=".mp4" :before-upload="handele_header">
                            <el-button type="primary" style="width:80px;height:30px;margin-left:16px" size="small">上传视频
                            </el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item class="video_img" v-if="show">
                    <div style="display:flex;flex-wrap:wrap;width:472px">
                        <div v-for="(item,index) in tu_po" :key="index"
                            style="width:100px;height:100px;display:flex;flex-wrap:wrap;margin:4px">
                            <img :src="item.img" style="width:100%;height:100%;" alt="">
                        </div>
                        <div v-for="(item,index) in shi_po" :key="index"
                            style="width:100px;height:100px;display:flex;flex-wrap:wrap;margin:4px">
                            <video :src="item.vid" style="width:100%;height:100%" controls autoplay></video>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="发布日期">
                    <el-date-picker v-model="msg_date" type="date" placeholder="选择发布日期" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" size="mini">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <!-- 提交 重置 按钮 -->
            <div class="formBtn">
                <div class="del_tips">以上信息一经发送即刻生效</div>
                <el-button type="primary" class="add_confirm" @click="handle_confirm">{{btn_name}}</el-button>
                <el-button class="add_Reset" @click="handle_reset">重置</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">{{del_tip}}</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getManageApi, showManageApi, selManageApi, addManageApi, editManageApi, removeManageApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // token
            myHeaders: {
                'Token': ''
            },
            // 登录用户id
            user_id: '',
            // 选项卡
            activeName: 'first',
            bul_show: true,
            tea_show: false,
            type: '',
            from: '',
            // 弹框显示隐藏
            add_edit_dialog: false,   //添加编辑弹框
            del_dialog: false,    //删除弹框
            // 删除数据的数组
            del_arr: [],
            // 删除提示语
            del_tip: '',
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            multiple_arr: [],
            // 分页--------------------------
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 搜索下拉框 校区
            campus_arr: [],
            campus_id: '',
            // 弹框内容
            title_name: '',  //弹框名字
            btn_name: '',
            title: '',   //  标题
            school: [],    // 校区
            school_name: [],
            class_name: [],    //班级
            class_arr: [],
            parent_arr: [],   //家长
            parent_id: [],
            publisher: [],   //发布人
            publisher_id: '',
            push_content: '',   //发布内容
            msg_date: '',     //发布日期
            // 上传图片地址
            img_url: '',
            img_arr: [],  //要上传给后端的数据
            tu_po: [],
            // 上传视频地址
            video_url: '',
            video_arr: [],  //要上传给后端的数据
            shi_po: [],
            // 视频图片是否显示
            show: false,
            img_show: false,
            video_show: false,
        }
    },
    created () {
        this.initData()
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.from = 1;
                this.initData();
                this.bul_show = true;
                this.tea_show = false;
            } else {
                this.from = 2;
                this.initData();
                this.bul_show = false;
                this.tea_show = true;
            }
        },
        // 子组件传过来的当前页数
        changePage (data) {
            this.page = data;
        },
        // 初始化数据 
        initData () {
            this.user_id = sessionStorage.getItem('id');
            if (this.activeName == 'first') {
                this.from = 1;
            } else {
                this.from = 2;
            };
            getManageApi({
                page: this.page,   //当前页
                name: this.search_inp,  //校区名称
                campus_id: this.campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
                type: this.from,
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                    this.campus_arr = res.campus;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 表格的方法
        toggleSelection (rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        SelectionChange (val) {
            this.multiple_arr = val;
        },
        // 点击添加按钮
        handle_add () {
            this.add_edit_dialog = true;
            this.title_name = '添加';
            this.btn_name = '确认添加';
            showManageApi({
                type: 1,
                from: this.from,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school = res.campus;    //所有校区
                    if (this.activeName == 'first') {
                        this.publisher = res.creator;   //发布人
                    }
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 校区下拉框改变
        Change_campus () {
            if (this.school_name.length == 0) {
                this.class_arr = [];
                this.class_name = [];
                this.parent_arr = [];
                this.parent_id = [];
            } else {
                this.class_arr = [];
                this.class_name = [];
                this.parent_arr = [];
                this.parent_id = [];
                selManageApi({
                    campus_uid: this.school_name,     //校区id
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.class_arr = res.class;    //班级
                        setTimeout(() => {
                            this.$refs.sel.blur()
                        }, 4000)
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }

        },
        // 班级下拉框改变
        Change_class () {
            if (this.class_name.length == 0) {
                this.parent_arr = [];
                this.parent_id = [];
            } else {
                this.parent_arr = [];
                this.parent_id = [];
                selManageApi({
                    class_uid: this.class_name,   //班级id
                    campus_uid: this.school_name,     //校区id
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.parent_arr = res.parent;    //家长
                        if (this.activeName != 'first') {
                            this.publisher = res.creator;   //发布人
                        };
                        setTimeout(() => {
                            this.$refs.sel_class.blur()
                        }, 4000)
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }

        },
        // 点击上传图片  视频前
        handele_header () {
            this.myHeaders.Token = sessionStorage.getItem("Token");
        },
        // 照片上传成功
        handleSuccess_img (res, file, fileList) {
            if (res.status.code == 200) {
                this.show = true;
                this.$message.success('图片上传成功！');
                // this.img_url += res.status.img + ',';
                let a = res.status.img;
                this.img_arr.push(a);
                let obj = {
                    'img': a
                };
                this.tu_po.push(obj);
            } else {
                this.show = false;
                this.$message.error(res.status.msg);
            }
        },
        // 视频上传成功后
        handleSuccess_video (res, file, fileList) {
            if (res.status.code == 200) {
                this.show = true;
                this.$message.success('视频上传成功！');
                // this.video_url += res.status.img + ',';
                let a = res.status.img;
                this.video_arr.push(a);
                let obj = {
                    'vid': a
                };
                this.shi_po.push(obj);
            } else {
                this.show = false;
                this.$message.error(res.status.msg);
            }
        },
        // 自动隐藏下拉框
        handle_sel () {
            setTimeout(() => {
                this.$refs.sel_pa.blur()
            }, 4000)
        },
        // 点击修改按钮
        handle_Edit (id) {
            this.id = id;
            this.add_edit_dialog = true;
            this.title_name = '修改';
            this.btn_name = '确认修改';
            showManageApi({
                id: id,
                type: 2,
                from: this.from,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.publisher = res.creator;   //发布人
                    this.school = res.campus;    //所有校区
                    this.class_arr = res.class;   //班级
                    this.parent_arr = res.parent;   //家长
                    this.title = res.data.title;   //标题
                    this.school_name = res.data.campus_uid; //校区id
                    this.class_name = res.data.class_uid;   //班级id
                    this.parent_id = res.data.parent_uid;   //家长id
                    this.publisher_id = res.data.creat_uid;   //发布人id
                    this.push_content = res.data.content;   //内容
                    this.msg_date = res.data.send_time;    //发布日期
                    let a = res.data.msg_img;  //图片
                    let b = res.data.msg_vid;   //视频
                    if (a.length != 0 || b.length != 0) {
                        this.show = true;
                        if (a.length != 0) {
                            this.img_show = true;
                            this.img_arr = a;
                            this.tu_po = a.map(item => {
                                return {
                                    img: item
                                }
                            })
                        } else if (b.length != 0) {
                            this.video_show = true;
                            this.video_arr = b;
                            this.video_arr = a;
                            this.shi_po = b.map(item => {
                                return {
                                    vid: item
                                }
                            })
                        }
                    } else if ((a.length == 0 && b.length == 0)) {
                        this.show = false;
                    };
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 添加修改点击   提交   按钮
        handle_confirm () {
            if (this.title_name == '添加') {
                addManageApi({
                    title: this.title,  //标题
                    campus_uid: this.school_name, //校区id
                    class_uid: this.class_name,  //班级id
                    parent_uid: this.parent_id,  //家长id
                    content: this.push_content,   //内容
                    creat_uid: this.publisher_id,   //发布人id
                    auth_userid: this.user_id, //登录用户
                    type: this.from,
                    msg_img: this.img_arr,  //上传的图片
                    msg_vid: this.video_arr,  //上传的视频
                    created_at: this.msg_date,   //发布日期
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('添加成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            } else if (this.title_name == '修改') {
                editManageApi({
                    id: this.id,
                    title: this.title,  //标题
                    campus_uid: this.school_name, //校区id
                    class_uid: this.class_name,  //班级id
                    parent_uid: this.parent_id,  //家长id
                    content: this.push_content,   //内容
                    creat_uid: this.publisher_id,   //发布人id
                    auth_userid: this.user_id, //登录用户
                    type: this.from,
                    msg_img: this.img_arr,  //上传的图片
                    msg_vid: this.video_arr,  //上传的视频
                    created_at: this.msg_date,   //发布日期
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.$message.success('修改成功');
                        this.initData();
                        this.handle_reset();
                        this.add_edit_dialog = false;
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 添加修改点击   重置  按钮
        handle_reset () {
            this.title = '';   //  标题
            this.school_name = [];   // 校区
            this.class_name = [];   //班级
            this.parent_id = [];   //家长
            this.publisher_id = '';  //发布人
            this.push_content = '';   //发布内容
            this.show = false;   //图片
            this.img_url = '';
            this.video_url = '';
            this.img_arr = [];
            this.video_arr = [];
            this.tu_po = [];
            this.shi_po = [];
        },
        // 点击单个删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_tip = '确定删除此项？';
            this.del_arr.push(id);
        },
        // 多个删除
        handle_del_mult () {
            this.del_dialog = true;
            this.del_tip = '确定删除所选中的选项？';
            for (let i = 0; i < this.multiple_arr.length; i++) {
                let del_id = this.multiple_arr[i].uid;
                this.del_arr.push(del_id);
            };
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击  提交 按钮
        handle_del () {
            removeManageApi({
                id: this.del_arr,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 弹窗关闭
        handleClose () {
            this.add_edit_dialog = false;
            this.del_dialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.amher {
    width: 100%;
    height: 100%;
    display: flex;
    // 选项卡
    .el-tabs {
        width: 100%;
        ::v-deep.el-tabs__active-bar {
            display: none;
        }
        ::v-deep.el-tabs__item {
            width: 144px;
            height: 40px;
            text-align: center;
            color: #525252;
            font-size: 18px;
            font-weight: 900;
        }
        ::v-deep.el-tabs__item:hover {
            color: #333333;
            font-size: 18px;
            font-weight: 900;
        }
        ::v-deep.el-tabs__item.is-active {
            width: 144px;
            height: 40px;
            // text-align: center;
            border-radius: 24px;
            background: #2479dd;
            color: #ffffff;
            font-weight: 500;
        }
        ::v-deep#tab-first {
            text-align: right;
        }
        ::v-deep#tab-second {
            text-align: left;
        }
        ::v-deep.el-tabs__nav-wrap::after {
            position: static !important;
        }
        .bul_cri {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #2479dd;
            position: absolute;
            left: 0;
            top: 16px;
        }
        // 表格显示内容
        .avatar_table {
            margin-bottom: 20px;
            // 下拉框  添加  删除按钮部分
            .out_sel_btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // 下拉框部分
                .sel_inp {
                    margin-top: 15px;
                    display: flex;
                    .el-select {
                        width: 140px;
                        height: 30px;
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                        ::v-deep.el-input__inner {
                            width: 140px;
                            height: 30px;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            ::v-deep.el-select__caret {
                                line-height: 30px;
                            }
                            ::v-deep.el-input__icon {
                                line-height: 30px;
                            }
                        }
                    }
                    .el-input {
                        width: 155px;
                        height: 30px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                        border-left: 0px;
                        ::v-deep.el-input__inner {
                            width: 155px;
                            height: 30px;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-left: 0px;
                        }
                    }
                }
                // 添加  删除按钮部分
                .add_del_btn {
                    text-align: right;
                    .el-button {
                        width: 80px;
                        height: 30px;
                        line-height: 4px;
                    }
                    // .addBtn {
                    //     margin-right: 8px;
                    // }
                }
            }
            // 表格内容
            .table_init {
                margin-top: 20px;
                text-align: center;
                // 操作按钮
                span {
                    border: none;
                    width: 24px;
                    height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
            //照片上传
            .img_load {
                ::v-deep.el-input__inner {
                    width: 288px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .del_tips {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 12px;
                color: #d61c1c;
            }
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>